.App {
  text-align: center;
}

body{
  font-family: Consolas,Lucida Console,monospace; 
  font-size: 12px;
  letter-spacing: 0.08em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(12, 12, 12);
  color: whitesmoke;
  
}


a{
  text-decoration: none;
  color:white;
}

a.niceLinks {
  text-decoration: none;
  font-weight: bold;
  
  transition: all 0.25s linear;
  position: relative;
}

a.niceLinks:before {
  content: "";
  display: block;
  width: 100%;
  height: 3px;
  background-color: #F13C20;
  position: absolute;
  left: 0;
  bottom: -3px; /* this is to match where the border is */
  transform-origin: left; 
  transform: scale(0);
  transition: 0.25s linear;
/*   will-change: transform; */
}

a.niceLinks:hover:before {
  transform: scale(1);
}

.hide{
  display: none;
}

a.link{
  text-decoration: none;
  color: whitesmoke;
  border-bottom: 3px solid #ec4848;
  transition: 0.7s ease-in-out;
}

a.link:hover{
  border-bottom: 0px solid;
  text-shadow: 0 0 30px rgba(33, 33, 33, 0.856);
}

.header{
  font-family: Poppins,sans-serif;
  height: 100px;
}

a.headername{
 
  text-align: left;
  color: whitesmoke;
  display: block;
  font-size: 26px;
  font-weight: 400;
  text-decoration:none;
  padding: 20px;
  transition: 0.7s ease-in-out;
}

a.headername:hover{
  transition: 0.7s ease-in-out;
  font-size: 15px;
  padding: 30px;
}

div.leftlinks{
  z-index: 1000;
  text-align: right;
  position: fixed;
  top: 35px;
  right: 20px;
  transition: 0.2s ease-in-out; 
}

a.headerlink{
  min-width: 80px;
  font-size: 15px;
  padding:15px;
  margin-top: 10px;
  margin-right: 10px;
  position:static;
  text-decoration: none;
  background-color: black;
  border-radius: 40px;
  color:whitesmoke;
  transition: 0.5s ease-in-out;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.5)
}

a.headerlink:hover{
  opacity: 0.70;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.6);
}

a.hiddenwhenbig{
  display: none;
}


p.headerlink{
  min-width: 80px;
  font-size: 15px;
  padding:15px;
  margin-top: 10px;
  margin-right: 10px;
  position:static;
  text-decoration: none;
  background-color: black;
  border-radius: 40px;
  color:whitesmoke;
  transition: 0.5s ease-in-out;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.5)
}

p.headerlink:hover{
  opacity: 0.85;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.6);
}

p.hiddenwhenbig{display: none;}

@media (max-width: 700px) {


  div.leftlinks{
    Overflow: visible;
    display: grid;
    z-index: 1000;
    text-align: center;
    position: fixed;
    bottom: 15px;
    right: 20px;  
    top: unset; 
  }


  a.headerlink{
    min-width: 80px;
    font-size: 12px;
    margin-top: 0px;
    padding:15px;
    margin-bottom: 5px;
    margin-right: 5px;
    position:static;
    text-decoration: none;
    background-color: black;
    
    border-radius: 40px;
    color:whitesmoke;
    transition: 0.7s ease-in-out;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.5)
  }

  a.collect {
    top:unset;
    bottom: 15px;
    right: 20px;
    padding:0px;
    min-height: 40px;
    font-size: 3px;
    min-width: 50px;
    position: fixed;
    transition:  0.7s ease-in-out;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
  }

  p.headerlink{
    display: block;
    z-index: 1002;
    opacity: 0;
    font-size: 0px;
    min-width: 0px;
    font-size: 0px;
    top:unset;
    bottom: 15px;
    right: 20px;
    padding:0px;
    margin-top: 0px;
    margin-right: 0px;
    position: fixed;
    background-color: black;
    border-radius: 60px;
    color:whitesmoke;
    transition: 0.7s ease-in-out;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.5)
  }

  p.collect {
    z-index: 1004;
    opacity: 1;
    margin: 0px;
    top:unset;
    bottom: 15px;
    right: 20px;
    padding:12px;
    font-size: 15px;
    min-width: 60px;
    position: fixed;
    transition:  0.7s ease-in-out;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
  }

  p.hiddenwhenbig{
    display: block;
  }
}

h3{
  font-weight: bolder;
}


h1 {
  text-align: center;
  font-size: 20px;
  font-weight: bold;  
}

div.footer{
  position: fixed;
  bottom:0;
  width: 100%;
}
.footerDiv{
  transform: translateX(-50%);
  margin-left: 50%;
  width: fit-content;
}

.footerText1{
  font-size: 9px;
 
}

.footerText2{

  margin-left: 10px;
 

}

.showCaseImage{
  width: 80%;
  max-width: 500px;
  }

.Letterbox{
  width: 100%;
  height: 80%;
  display: block;
  position: absolute;
  
}

p.emojibutton{
  text-align: left;
  padding-left: 25px;
  font-size: 65px;                 
  position: fixed;
  bottom:0;
  transition: 0.5s;
  text-decoration: none;
}

p.emojibutton:hover{
  text-shadow: 0 0 30px rgba(33, 33, 33, 0.856);
  font-size: 85px;
}
