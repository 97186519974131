.App {
  margin: 0;
  font-family: "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}




.centerlogo {
  left: 25%;
  position: fixed;
  bottom: 100%;
  width: 50%;
}


img.heroify-logo {
  width: 40%;
  max-width: 500px;
  min-width: 100px;
}

.flexParent {
  display: flex;
  flex-direction: column;
}



.slider {
  width: 80%;
  padding-left: 20%;
}
.body {
  overflow: scroll;
  
}




.hide {
  display: none;
}

table.ProgressBar {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  width: 300px;
  height: 10px;
  border-collapse: separate;
}

td.currentstep {
  width: auto;
  background-color: #efd82d;
  border-radius: 9px;
  -moz-border-radius: 6px;
  height: 100%;
}

td.notcurrentstep {
  width: auto;
  border-radius: 9px;
  -moz-border-radius: 6px;
  height: 100%;
  background-color: rgba(212, 205, 205, 0.548);
}
.viewHeader {
  margin-left: 0px;
  text-align: center;
  font-size: 30px;
  font-family: "Avenir";
  font-weight: bold;
}

.wellcomecenter {
  text-align: center;
}

p.center {
  padding: 10px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

p.closeEx {
  position: absolute;
  top: -5px;
  right: 15px;
  font-family: "Avenir";
  font-size: 30px;
}

.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.popup_inner {
  position: absolute;
  left: 25%;
  right: 25%;
  top: 25%;
  
  text-align: center;
  margin: auto;
  background: #efd82d; 
  font-family: "Avenir";
  font-weight: bold;
  color:black;
}

.popup_inner_playlists {
  
  position: absolute;
  left: 10%;
  right: 10%;
  top: 20%;
  
  text-align: center;
  margin: auto;
  background: #efd82d; 
  font-family: "Avenir";
  font-weight: bold;
  color:black;
}
.popup-container {
  margin: 10px;
}

p.FAQbutton {
  position: absolute;
  right: 30px;
  font-size: 28px;

}

.creatingPlaylist {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: #c62e2e;
}

image {
  height: 20%;
  display: block;
}

.main {
  margin-top: 2px;
  margin-bottom: 2px;
  border-color: black;
  text-align: center;
}

.list {
  width: 25px;
}


.divider {
  padding-bottom: 20px;
}

.searchbox {
  width: 360px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  height: 20px;
}

#searchInput {
  width: 250px;
  height: 40px;
  float: left;
  font-size: 14px;
  font-family: 'Avenir'
}

#searchbutton {
  margin-top: auto;
  margin-bottom: auto;
  float: right;
  width: 60;
  height: 40px;
}

.Herocard {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  margin: auto;
  max-width: 250px;
  background-color: #c62e2e;
  border-width: 5px;
  border-radius: 5px;
  color: #ffffff;
}

/* On mouse-over, add a deeper shadow */
.Herocard:hover {
  box-shadow: 0 15px 25px 0 rgba(0, 0, 0, 0.2);
}

img.heroPic {
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  height: auto;
  border-radius: 5px;
}

.searchresult {
  text-align: center;
}



.next {
  margin-left: 20px;
  float: left;
  font-weight: bold;
  background: black;
  font-family: 'Avenir'
}

.copy {
  font-size: 18px;
  font-family: 'Avenir','Nunito Sans', 'Lato';
  font-style: normal;
  font-weight: bold;
  color: black;
  width: 80%;
    max-width: 500px;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
}

.copy2 {
  font-size: 15px;
  padding-right: 15px;
  padding-left: 15px;
  font-family: 'Avenir','Nunito Sans', 'Lato';
  font-weight: lighter;
  color: black;
}

.copy3 {
  font-size: 30px;
  padding-right: 20px;
  padding-left: 20px;
  font-family: 'Avenir','Nunito Sans', 'Lato';
  font-style: normal;
  font-weight: bold;
  color: black;
}

.centered {
  position: fixed;
  top: 80%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}

.white {
  color: #ffffff;
}

.button {
  font-family: 'Avenir';
  text-decoration: none;
  text-align: center;
  color: black;
}

.heroify-logo-small{
  height: auto;
  width: 100px;
  margin-bottom: 20px;
}


.heroify-logo-large{
  height: auto;
  width: 400px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top:-40px;
}

.spinner-gif{
  height: auto;
  width: 300px;
}

.playlistTable{
  font-size: 12px;
  color: black;
  
}

.errorMessage{
  position: absolute;
  top: 25%;
  left: 25%;
  right: 25%;
  font-family:"Avenir";
  color: black;
}

@media (max-width: 700px) {
  p.FAQbutton {
    position: absolute;
    margin: 20px;
    right: 10px;
    font-size: 15px;
  
  }
  

  .heroify-logo-small{
    display: none;
  }

  .copy {
    font-size: 12px;
    font-family: 'Avenir','Nunito Sans', 'Lato';
    font-style: normal;
    font-weight: bold;
    color: black;
    
    padding-left: 20px;
    padding-right: 20px;
  }

  .copy3 {
    font-size: 20px;
    padding-right: 20px;
    padding-left: 20px;
    font-family: 'Avenir','Nunito Sans', 'Lato';
    font-style: normal;
    font-weight: bold;
    color: black;
  }

  .popup_inner {
    position: absolute;
    left: 8%;
    right: 8%;
    top: 5%;
    overflow: auto;
    text-align: center;
    margin: auto;
    background: #efd82d; 
    font-family: "Avenir";
    font-weight: bold;
    color:black;
  }
  .popup_inner_playlists {
  
    position: absolute;
    left: 8%;
    right: 8%;
    top: 5%;
    overflow: scroll;
    float: bottom;
    margin-bottom: 5px;

    text-align: center;
    margin: auto;
    background: #efd82d; 
    font-family: "Avenir";
    font-weight: bold;
    color:black;
  }
  .vjueHeader{
    font-size: 38px;
  }

  .heroify-logo-large{
    height: auto;
    width: 300px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top:-40px;
  }
  #searchInput{
    width: 200px;
    height: 37px;
    float: left;
    
    margin-left: 25px;
    font-size: 14px;
    font-family: "Avenir";}


}